import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-watchlist-modal',
  standalone: true,
  imports: [NgbModalModule, CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './watchlist-modal.component.html',
  styleUrl: './watchlist-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchlistModalComponent implements OnInit {
  @Input() actionType: any;
  @Input() watchlistName: any;
  watchListForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });
  isSubmitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.actionType === 'edit') {
      this.watchListForm.patchValue({
        name: this.watchlistName,
      });
    }
  }

  /**
   * watchListFormFC - get control of watchListForm
   */
  get watchListFormFC() {
    return this.watchListForm.controls;
  }
  /**
   * onSubmit - function check form is valid and send response
   */
  onSubmit() {
    this.isSubmitted = true;
    if (this.watchListForm.valid) {
      this.activeModal.close(this.watchListForm.value);
      this.isSubmitted = false;
      this.cdr.markForCheck();
    }
    this.isSubmitted = false;

  }

  /**
   * confirmDelete - emit action and name of watchlist
   */
  confirmDelete() {
    this.activeModal.close({ action: 'delete', name: this.watchlistName });
    this.cdr.markForCheck();
  }


}
