import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDropdownModule,
  NgbModal,
  NgbModule,
  NgbNavModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, filter, map, Subscription } from 'rxjs';
import { PAGINATION_OPTIONS } from '../../../../config/app.constant';
import { CustomDataTableComponent } from '../../../shared/components/custom-data-table/custom-data-table.component';
import { StockTableComponent } from '../../../shared/components/stock-table/stock-table.component';
import { HighlightWordPipe } from '../../../shared/pipes/highlight-word.pipe';
import { AuthService } from '../../../shared/services/auth.service';
import { DashboardService } from '../../../shared/services/dashboard.service';
import { LightStreamerService } from '../../../shared/services/light-streamer.service';
import { WatchlistModalComponent } from './watchlist-modal/watchlist-modal.component';
import { watchlistColumn } from './watchlist.data';

@Component({
  selector: 'app-watchlist-stock',
  standalone: true,
  imports: [
    CommonModule,
    NgbNavModule,
    CustomDataTableComponent,
    StockTableComponent,
    NgbModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    HighlightWordPipe,
    NgbTooltipModule,
  ],
  templateUrl: './watchlist-stock.component.html',
  styleUrl: './watchlist-stock.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchlistStockComponent implements OnInit, OnDestroy {
  subscriptions$: Subscription[] = [];
  watchList: any[] = [];
  userDetail: any;
  displayedColumnArray: any[] = watchlistColumn;
  watchlistData: any[] = [];
  tableOptions = {
    fetchingData: false,
    page: PAGINATION_OPTIONS.INITIAL_PAGE_INDEX,
    limit: PAGINATION_OPTIONS.INITIAL_PAGE_SIZE,
    totalRecord: 0,
    notDataMessage: 'Data Not Found!',
    showTotalRecords: false,
    showPagination: false,
  };
  stockIndexes: any = {};
  searchControl = new FormControl();
  activeId = 1;
  activeWatchlistId: any;
  resultSets: any;
  opened: any = false;
  isLoading: any = false;
  @ViewChild('searchStock') searchStock!: ElementRef;
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const clickedInside = this.searchStock.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.searchControl.reset();
      this.opened = false;
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private lightStreamService: LightStreamerService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private authService: AuthService,
  ) {}

  /**
   * ngOnInit - Initialize get WatchList and UserDetail
   */
  ngOnInit(): void {
    this.getWatchList();
    this.userDetail = this.authService.decryptedUserDetails();
    this.onSearch();
    this.cdr.markForCheck();
  }

  /**
   * ngOnDestroy - Unsubscribe subscription variable
   */
  ngOnDestroy(): void {
    this.subscriptions$.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  /**
   * onLiveData - This function is fetch livestream data and assign to watchlist ticker
   */
  onLiveData() {
    const tempStockList: any[] = [];
    this.watchlistData.forEach((stock: any, index: any) => {
      this.stockIndexes[stock?.name] = index;
      tempStockList.push(stock?.name);
    });
    console.log(`stockIndexes :>> `, this.stockIndexes);
    console.log(`tempStockList :>> `, tempStockList);
    this.lightStreamService.connect();
    this.lightStreamService.subscribeToData(
      tempStockList,
      ['stock_name', 'last_price', 'full_data'],
      (data) => {
        const getParseStockData = JSON.parse(data?.full_data) || {};

        if (getParseStockData && getParseStockData?.ev == 'T') {
          const getIndex = this.stockIndexes[data?.stock_name];
          if (getIndex >= 0) {
            this.watchlistData[getIndex].price = getParseStockData?.p  ;
          }
        }
        console.log('',);
        this.cdr.markForCheck();
      },
    );
  }

  /**
   * onNavChange - Change watchlist and call getWatchListById API to fetch new list
   * @param event Nav tab changeEvent
   */
  onNavChange(event: any) {
    this.activeId = event.nextId;
    this.activeWatchlistId = this.watchList[this.activeId - 1]?._id;
    this.getWatchListById();
    this.cdr.markForCheck();
  }

  setSearchValue() {
    this.searchControl.setValue('AAAA');
    this.searchStock.nativeElement.value = 'AAAA';
    this.cdr.markForCheck();
  }

  /**
   * onSearch - function can search with debounce and call the searchStock api
   */
  onSearch() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        map((value) => {
          if (!value || value.length < 3) {
            this.resultSets = null;
          }
          return value;
        }),
        filter((value) => value && value.length >= 1),
      )
      .subscribe((value) => {
        this.opened = true;
        this.getSearchList({
          search: value,
          watchlistId: this.activeWatchlistId,
        });
      });
  }

  /**
   * openAddModal - This function will open modal for add WatchList
   */
  openAddModal() {
    const modalRef = this.modalService.open(WatchlistModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.actionType = 'add';
    modalRef.componentInstance.watchlistName = '';
    modalRef.result.then(
      (result) => {
        if (result) {
          const watchListData = {
            userId: this.userDetail._id,
            ...result,
          };
          this.addWatchList(watchListData);
        }
      },
      (reason) => {
        console.log('Dismissed:', reason);
      },
    );
  }

  /**
   * openEditModal - This function will open modal for edit watchlist
   * @param watchlistName Existing Watchlist name
   */
  openEditModal(watchlistName: string) {
    const modalRef = this.modalService.open(WatchlistModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.actionType = 'edit';
    modalRef.componentInstance.watchlistName = watchlistName;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.updateWatchList({
            ...result,
            tickers: [],
          });
        }
      },
      (reason) => {
        console.log('Dismissed:', reason);
      },
    );
  }

  /**
   * openEditModal - This function will open modal for delete watchlist
   * @param watchlistName Existing Watchlist name
   */
  openDeleteModal(watchlistName: string) {
    const modalRef = this.modalService.open(WatchlistModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.actionType = 'delete';
    modalRef.componentInstance.watchlistName = watchlistName;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.removeWatchList();
        }
      },
      (reason) => {
        console.log('Dismissed:', reason);
      },
    );
  }

  /**
   * getWatchList - Call GetWatchList API
   */
  getWatchList() {
    const getWatchListApi = this.dashboardService.getWatchList().subscribe({
      next: (res: any) => {
        if (res?.data && res?.type == 'success') {
          this.watchList = res?.data || [];
          this.activeWatchlistId = this.watchList[this.activeId - 1]?._id || '';
          this.watchlistData = this.watchList[this.activeId - 1]?.stockDetails || []
          // this.onLiveData();
          this.cdr.markForCheck();
        }
      },
      error: () => {
        this.cdr.markForCheck();
      },
    });
    this.subscriptions$.push(getWatchListApi);
  }

  /**
   * addWatchList - Call Add WatchList API
   * @param watchListData Add WatchList-Data
   */
  addWatchList(watchListData: any) {
    const addWatchListApi = this.dashboardService
      .addWatchList(watchListData)
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success') {
            this.getWatchList();
            this.activeId = this.watchList?.length + 1;
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(addWatchListApi);
  }
  /**
   * updateWatchList - Call Update WatchList API
   * @param watchListData Updated WatchListData
   */
  updateWatchList(watchListData: any) {
    const updateWatchListApi = this.dashboardService
      .updateWatchList(this.activeWatchlistId, watchListData)
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success') {
            this.getWatchList();
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(updateWatchListApi);
  }
  /**
   * removeWatchList - Call remove WatchList API
   * @param watchListId remove WatchListID
   */
  removeWatchList() {
    const removeWatchListApi = this.dashboardService
      .removeWatchList(this.activeWatchlistId)
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success') {
            this.getWatchList();
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(removeWatchListApi);
  }

  /**
   * getSearchList - Get the search list API
   */
  getSearchList(queryParams: any) {
    this.dashboardService.searchStock(queryParams).subscribe({
      next: (res: any) => {
        if (res?.data) {
          console.log('ressdfs', res);
          this.resultSets = res?.data?.results || [];
          this.cdr.markForCheck();
        } else {
          this.resultSets = [];
          this.cdr.markForCheck();
        }
      },
      error: () => {
        this.resultSets = [];
        this.cdr.markForCheck();
      },
    });
  }
  /**
   * getWatchListById - Call GetWatchlist by ID API
   */
  getWatchListById() {
    if (this.isLoading) {
      return;
    }
    const watchlistId = this.watchList[this.activeId - 1]?._id;
    this.isLoading = true;
    const getWatchListByIdApi = this.dashboardService
      .getWatchListById(watchlistId)
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success' ) {
            this.watchlistData = res?.data?.stockDetails || []
            // this.onLiveData();
            this.isLoading = false;
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(getWatchListByIdApi);
  }
  /**
   * addStockToWatchList - Call AddStockToWatchList API
   * @param suggestion ticker Data
   * @param event stopPropagation of existing event
   */
  addStockToWatchList(suggestion: any, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const watchListData = {
      ticker: suggestion?.ticker,
      type: suggestion?.type || suggestion?.toShow ? 'remove' : 'add',
    };
    const addStockToWatchListApi = this.dashboardService
      .updateTicker(this.watchList[this.activeId - 1]?._id, watchListData)
      .subscribe({
        next: (res: any) => {
          if (res?.data && res?.type == 'success') {
            suggestion.toShow = suggestion?.toShow ? false : true;
            this.getWatchListById();
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(addStockToWatchListApi);
  }
  /**
   * handleTableDataChange - Call remove Stock from watchList API
   * @param event Output event of table
   */
  handleTableDataChange(event: any) {
    console.log('ticker', event);
    const watchListData = {
      ticker: event,
      type: 'remove',
    };
    this.addStockToWatchList(watchListData);
    this.cdr.markForCheck();
  }
}
