<div class="modal-header d-flex justify-content-between">
  <h4
    class="modal-title text-dark fw-medium"
    id="modal-basic-title"
  >
    {{
      actionType === 'add'
        ? 'Create WatchList'
        : actionType === 'edit'
        ? 'Edit WatchList'
        : 'Delete WatchList'
    }}
  </h4>
  <button
    class="btn btn-sm btn-light"
    (click)="activeModal.dismiss()"
  >
    <i class="bi bi-x text-primary fs-4 "></i>
  </button>
</div>
<form [formGroup]="watchListForm">
<div class="modal-body">
  @if (actionType !== 'delete') {
    <div class="col-md-12">
      <div class="form-group">
        <label
          for="name"
          class="form-label"
          >Name</label
        >
        <input
          type="text"
          class="form-control"
          id="name"
          formControlName="name"
          placeholder="Enter Name"
          maxlength="12"
          [ngClass]="{
            'border-danger':
              (watchListFormFC['name'].touched ||
                watchListFormFC['name'].dirty ||
                isSubmitted) &&
              watchListFormFC['name'].invalid
          }"
        />
        @if ( (watchListFormFC['name'].touched || watchListFormFC['name'].dirty
        || isSubmitted) && watchListFormFC['name'].invalid) {
        <div>
          @if (watchListFormFC['name'].errors?.['required']) {
          <div class="text-danger">Name is required.</div>
          }
        </div>
        }
      </div>
    </div>

  } @if (actionType === 'delete') {
  <div>
    <p>Are you sure you want to delete the watchlist <strong>{{ watchlistName }}</strong>?</p>
  </div>
  }
</div>
<div class="modal-footer d-flex justify-content-end">
  @if(actionType !== 'delete'){

  <button
    type="submit"
    class="btn btn-primary"
    (click)="onSubmit()"
  >
    Save
  </button>
  } @if (actionType === 'delete') {
  <button
    type="button"
    class="btn btn-primary"
    (click)="activeModal.close({ action: 'delete', name: watchlistName })"
  >
    Confirm
  </button>
  <button
    type="button"
    class="btn btn-light"
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>
  }
</div>
</form>
