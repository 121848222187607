<header class="page-header-section py-3 px-3 mb-4 border-bottom bg-primary">
  <div class="container">
    <div
      class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between"
    >
      <div class="col-md-3 mb-2 mb-md-0">
        <a
          href="/"
          class="d-inline-flex link-body-emphasis text-decoration-none me-3"
        >
          <img
            src="images/svgs/logo-white.svg"
            alt=""
          />
        </a>
      </div>
      <div class="col-md-3 text-end">
        @if(!authService.getLocalstorage('accessToken')){

        <a
          [routerLink]="['/auth/login']"
          class="btn btn-outline-light me-2"
          >Login</a
        >
        <a
          [routerLink]="['/auth/registration']"
          class="btn btn-light"
          >Sign-up</a
        >
        } @else {
        <a
          (click)="authService.logout()"
          class="btn btn-outline-light me-2"
          >Logout</a
        >

        }
      </div>
    </div>
  </div>
</header>
