<!-- <aside class="wishlist-section border-end"> -->
<div class="side-row1 d-flex border-bottom pt-2 px-2 justify-content-between">
  <h5 class="fw-bold me-1">
    NIFTY 50 <span class="text-danger">18181.75</span>
    <small class="text-muted">-104.75 (0.57%)</small>
  </h5>
  <h5 class="fw-bold">
    SENSEX <span class="text-danger">61560.64</span>
    <small class="text-muted">-371.83 (0.60%)</small>
  </h5>
</div>

<div class="whishlist-search border-bottom d-flex w100 align-items-center">
  <i class="bi bi-search text-mute ms-2"></i>
  <div style="width: 100% !important; position: relative">
    <input
      type="text"
      [formControl]="searchControl"
      #searchStock
      class="form-control form-control-sm border-0 bg-transparent"
      placeholder="Search (infy bse, nifty fut, etc)"
    />
    @if(resultSets?.length&& opened){
    <div class="suggestion-box">
      <ul class="suggestion-list">
        @for (suggestion of resultSets; track suggestion?.id) {
        <li>
          <div class="d-flex justify-content-between align-items-center">
            <div
              [innerHTML]="
                suggestion?.ticker | highlightWord : searchControl.value : 'igm'
              "
            ></div>
            <a
              [ngbTooltip]="suggestion?.toShow ? 'Remove Stock' : 'Add Stock'"
              style="cursor: pointer"
              (click)="addStockToWatchList(suggestion, $event)"
            >
              <i
                class="bi fs-5"
                [ngClass]="[
                  suggestion?.toShow
                    ? 'bi-dash-circle-fill text-light'
                    : 'bi-plus-circle-fill text-primary'
                ]"
              ></i>
            </a>
          </div>
        </li>
        <hr />
        }
      </ul>
    </div>
    }
  </div>
  <small class="text-mute"
    ><span>{{ watchlistData.length }}</span
    >/<span>50</span></small
  >
  <a class="p-2 h5 mb-0"><i class="bi bi-funnel-fill"></i></a>
</div>

<div
  class="stock-list-row"
  drag-scroll-item
>
  @if(watchList.length<=0) {
    <div class="text-center w100 h100 tab-content text-center">
      <i class="bi bi-binoculars fs-1 text-light"></i>
      <h3 class="text-light">Nothing Here</h3>
      <button class="btn btn-md btn-secondary mt-3" (click)="openAddModal()">
        Add WatchList
      </button>
    </div>
    
  } @else {
  <div [ngbNavOutlet]="nav"></div>
  }
  <div
    class="w100"
    style="background-color: #f6f9fd"
  >
    <div class="row d-flex align-items-center">
      <div class="col-11">
        <ul
          ngbNav
          #nav="ngbNav"
          class="nav nav-pills justify-content-start mb-0 mt-auto stock-list-scroll"
          [(activeId)]="activeId"
          (navChange)="onNavChange($event)"
        >
          @for (watchListitem of watchList; track i;let i= $index) {
          <li [ngbNavItem]="i + 1">
            <div
              ngbDropdown
              container="body"
            >
              <a
                ngbNavLink
                style="cursor: pointer"
                class="nav-link"
              >
                {{ watchListitem?.name }}
                <a
                  class="dropdown-toggle-split text-light"
                  style="cursor: pointer"
                  ngbDropdownToggle
                >
                </a>
              </a>
              <div ngbDropdownMenu>
                <button
                  class="dropdown-item"
                  (click)="openEditModal(watchListitem?.name)"
                >
                  Rename
                </button>
                <button
                  class="dropdown-item"
                  (click)="openDeleteModal(watchListitem?.name)"
                >
                  Delete
                </button>
              </div>
            </div>

            <ng-template ngbNavContent>
              @defer () { @if(watchlistData && watchlistData.length>0){
              <app-stock-table
                [showHeader]="true"
                [isActionButtonRequired]="true"
                [data]="watchlistData"
                (changeTableData)="handleTableDataChange($event)"
              ></app-stock-table>

              } }
              <div class="text-center p-4">
                @if(isLoading){
                <div
                  class="spinner-border text-primary"
                  style="width: 2rem; height: 2rem"
                  role="status"
                ></div>
                } @else if(watchlistData.length<=0 && !isLoading) {
                <i class="bi bi-binoculars fs-1 text-light"></i>
                <h3 class="text-light">Nothing Here</h3>
                <!-- <button
                  class="btn btn-md btn-secondary"
                  (click)="setSearchValue()"
                >
                  Add Stock
                </button> -->

                }
              </div>
            </ng-template>
          </li>
          }
        </ul>
      </div>
      <div
        class="col h100"
        style="margin-left: -9px"
      >
        <a
          (click)="openAddModal()"
          style="cursor: pointer"
          [ngbTooltip]="'Create WatchList'"
        >
          <i
            class="bi bi-plus-circle"
            style="font-size: 19px"
          ></i>
        </a>
      </div>
    </div>
  </div>
</div>

<!-- </aside> -->
