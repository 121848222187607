import { Pipe, PipeTransform } from '@angular/core';
import { API_PREFIX } from '../../../environments/environment';

@Pipe({
  name: 'imageDomainChange',
  standalone: true,
})
export class ImageDomainChangePipe implements PipeTransform {
  defaultImage = '/public/images/avatar.jpg';
  transform(value: string) {
    if (value) {
      value = API_PREFIX +'/img/'+ value;
    } else {
      value = this.defaultImage;
    }
    return value;
  }
}
