<footer >
    <div class="container">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between flex-column flex-md-row">

            <div  class=" text-center text-md-end  ">
                <a href="call:+1 999 999 9999" class="text-primary mx-2 text-decoration-none"><img src="/images/svgs/icn-call.svg" alt=""> +1 999 999 9999</a>
                <a href="mailto:support@ima.com" class="text-primary mx-2 text-decoration-none"> <img src="/images/svgs/icn-email.svg" alt=""> support&#64;ima.com</a>
                
            </div>
            <p class=" mb-0 text-body-secondary text-center ">© 2024 Company, Inc</p>
            <ul class="nav justify-content-center justify-content-md-start">
                <li class="nav-item"><a href="pricing.html" class="nav-link px-2 text-body-secondary">Pricing</a></li>
                <li class="nav-item"><a href="#" class="nav-link px-2 text-body-secondary">FAQs</a></li>
                <li class="nav-item"><a href="#" class="nav-link px-2 text-body-secondary">Privacy</a></li>
                <li class="nav-item"><a href="#" class="nav-link px-2 text-body-secondary">Terms</a></li>
            </ul>
        </div>
    </div>
</footer>