import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageDomainChangePipe } from '../../pipes/image-domain-change.pipe';
import { AuthService } from '../../services/auth.service';
import { navDataList } from './header.data';

@Component({
  selector: 'app-main-header',
  standalone: true,
  imports: [CommonModule,NgbDropdownModule,RouterModule,ImageDomainChangePipe],
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.scss'
})
export class MainHeaderComponent implements OnInit{
  navList=navDataList
  userDetail:any
  constructor(public authService:AuthService){}
  ngOnInit(): void {
    this.userDetail = this.authService.decryptedUserDetails();
  }
}
