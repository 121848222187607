import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-header',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './auth-header.component.html',
  styleUrl: './auth-header.component.scss',
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class AuthHeaderComponent {

  constructor(public authService:AuthService){}

}
