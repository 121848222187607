export const watchlistColumn=[
    {
        field:'ticker',
        label:'Name',
        isComponentSide:false,
        type:'string',
        isSortable:false
    },
    {
        field:'todaysChange',
        label:'Change',
        isComponentSide:false,
        type:'number',
        isSortable:false
    },
    {
        field:'todaysChangePerc',
        label:'Change(%)',
        isComponentSide:false,
        type:'percentage',
        isSortable:false
    },
    {
        field:'updated',
        label:'Price',
        isComponentSide:true,
        type:'string',
        isSortable:false
    },

];

export const watchlistStockDataList=[
    {
        ticker:'SOUTHBANK',
        change:'-0.35',
        changePerc:'-2.03',
        price:'16.90'
    },
    {
        name:'SOUTHBANK',
        change:'-0.35',
        changePerc:'-2.03',
        price:'16.90'
    },
    {
        name:'SOUTHBANK',
        change:'-0.35',
        changePerc:'-2.03',
        price:'16.90'
    },
    {
        name:'SOUTHBANK',
        change:'-0.35',
        changePerc:'-2.03',
        price:'16.90'
    },
    {
        name:'SOUTHBANK',
        change:'-0.35',
        changePerc:'-2.03',
        price:'16.90'
    },
    {
        name:'SOUTHBANK',
        change:'-0.35',
        changePerc:'-2.03',
        price:'16.90'
    },
    {
        name:'SOUTHBANK',
        change:'-0.35',
        changePerc:'-2.03',
        price:'16.90'
    },
]