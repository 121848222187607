import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { routes } from './app.routes';
import { SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { authInterceptor } from './shared/interceptor/auth.interceptor';
import {  NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    importProvidersFrom([
      NgbNavModule,
      CarouselModule,
      ToastrModule.forRoot(),
      ToastNoAnimationModule.forRoot(),
    ]),
    // provideServiceWorker('ngsw-worker.js', {
    //         enabled: environment.production,
    //         registrationStrategy: 'registerWhenStable:30000'
    //       })
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
  ],
};
