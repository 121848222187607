import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../shared/components/footer/footer.component';
import { MainHeaderComponent } from '../shared/components/main-header/main-header.component';
import { WatchlistStockComponent } from './dashboard/watchlist-stock/watchlist-stock.component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [RouterOutlet,MainHeaderComponent,FooterComponent,WatchlistStockComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {

}
